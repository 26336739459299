<template>
  <div>
    <div class="container">
      <div class="head">
        <b-navbar class="navbar-transparent" toggleable="lg" type="light" variant="light" fixed="top">
          <b-navbar-brand href="/">
            <img :src="gSetting.common.logo" height="32" alt />
          </b-navbar-brand>
          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="ml-auto">
              <b-nav-item href="#" :to="{path:'/'}" class="nav">首页</b-nav-item>
              <b-nav-item href="#" :to="{path:'/download'}">下载中心</b-nav-item>
              <b-nav-item href="#" :to="{path:'/buy'}">购买正版</b-nav-item>
              <b-nav-item href="#" :to="{path:'/guestbook'}">在线反馈</b-nav-item>
              <b-nav-item href="#" :to="{path:'/agent'}">代理加盟</b-nav-item>
              <b-nav-item href="#" :to="{path:'/about'}">关于</b-nav-item>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </div>
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
export default {
  name: 'HeadNav',
  data() {
    return {
      isNavBackgroud: false
    }
  },
  watch: {},
  computed: {},
  methods: {
    handleScroll() {
      let scrollTop = document.body.scrollTop || document.documentElement.scrollTop
      if (scrollTop >= 10) {
        this.isNavBackgroud = true
      } else {
        this.isNavBackgroud = false
      }
    }
  },
  created() {},
  mounted() {}
}
</script>
<style lang="scss" scoped>
@media screen and (max-width: 576px) {
}

.navbar {
  -webkit-box-shadow: 2px 0 8px #ccc;
  box-shadow: 2px 0 8px #ccc;
}
.navbar-light .navbar-nav .nav-link {
  color: #333;
  padding-left: 20px;
  padding-right: 20px;
  &.router-link-exact-active {
    font-weight: bold;
  }
}
</style>