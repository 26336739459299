import axios from 'axios'
import gSetting from '@/common/setting/index'


// create an axios instance
const service = axios.create({
  baseURL: gSetting.common.apiUrl, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
      // 在发送请求之前做些什么
    
    return config
  },
  error => {
      // 对请求错误做些什么
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    // console.log('response',response)
    if(response.status == 200)
    {
      if(response.data.status == 0){
        //成功
        return response.data
      }else{
        alert(response.data.message)
        return Promise.reject(response.data.message)
      }
    } else {
      const error = response.status+':'+response.statusText
      alert(error)
      return Promise.reject('error:'+error)
    }
  },
  error => {
    console.error('request_web error:' + error) // for debug
    // Message({
    //   message: error.message,
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    return Promise.reject(error)
  }
)


export default service
