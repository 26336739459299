import request from '@/common/request/request'

function getList(params) {
  return request({
    url: '/Guestbook/GetList',
    method: 'get',
    params
  }) 
}

function getTotal(params) {
  return request({
    url: '/Guestbook/GetTotal',
    method: 'get',
    params
  }) 
}

function getOne(params) {
  return request({
    url: '/Guestbook/GetOne',
    method: 'get',
    params
  }) 
}
function create(data) {
  return request({
    url: '/Guestbook/Create',
    method: 'post',
    data
  }) 
}

export default {
  getList,
  getTotal,
  getOne,
  create
}
