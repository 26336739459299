import request from '@/common/request/request'

function getList(data) {
  return request({
    url: '/Soft/GetList',
    method: 'post',
    data
  }) 
}
function getPrice(data) {
  return request({
    url: '/Soft/GetPrice',
    method: 'post',
    data
  }) 
}

export default {
  getList,
  getPrice
}
