
// 从谁开始 fromTenant
function saveFromTenant(fromTenant) {
    if (!localStorage.getItem('fromTenant')) {
        if (fromTenant) {
            localStorage.setItem('fromTenant', fromTenant)
            console.log('fromTenant 保存成功：', fromTenant)
        } else {
            console.log('fromTenant 未发现')
        }
    } else {
        console.log('fromTenant 已有跳过 当前：', localStorage.getItem('fromTenant'))
    }
}

// 解析 邀请码invite
function saveInvite(query) {
    if (query.invite) {
        localStorage.setItem('invite', query.invite)
        console.log('邀请码 保存成功', query.invite)
    } else {
        console.log('invite 未发现')
    }
}

//获取 fromTenant
function getFromTenant() {
    return localStorage.getItem('fromTenant') || ''
}

//获取 fromTenant
function getInvite() {
    return localStorage.getItem('invite') || ''
}

export default {
    saveFromTenant,
    saveInvite,
    getFromTenant,
    getInvite
}