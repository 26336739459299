
import API from '@/api/soft'
import API_Guestbook from '@/api/guestbook'
import store from '@/store/index'

function refreshSoftCates() {
  // console.log('store.state.dataCache.softCates1',store.state.dataCache.softCates)
  API.getList({}).then(res => {
    let cates = []
    res.data.map(item => {
      if (item.cate == '' || item.cate == '默认分类') {
        return
      }
      let idx = -1
      cates.some((item1, index1) => {
        if (item1.name == item.cate) {
          idx = index1
          return true
        }
      })

      if (idx < 0) {
        cates.push({ name: item.cate, softs: [] })
        idx = cates.length - 1
      }
      cates[idx].softs.push(item)
    })
    store.dispatch('dataCache/setSoftList', res.data)
    store.dispatch('dataCache/setSoftCates', cates)
    // console.log('store.state.dataCache.softCates2',store.state.dataCache.softCates)
  })
  return store.state.dataCache.softCates
}
function refreshGuestbooks() {
  API_Guestbook.getList({}).then(res => {
    store.dispatch('dataCache/setGuestbooks', res.data)
  })
  return store.state.dataCache.guestbooks
}
function getSoftIcon(code) {
  try {
    return require(`@/assets/images/product/logo_${code}.png`)
  } catch {
    try {
      return require(`@/assets/images/product/logo_default.png`)
    } catch {
      return ''
    }
  }
}

export default {
  refreshSoftCates,
  refreshGuestbooks,
  getSoftIcon
}