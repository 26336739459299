<template>
  <div>
    <head-nav />
    <div>
      <router-view></router-view>
    </div>
    <footer-bar />
  </div>
</template>

<script>
import HeadNav from './components/headNav'
import FooterBar from './components/footer'

export default {
  name: 'Layout',
  components: {
    HeadNav,
    FooterBar
  },
  computed: {},
  methods: {}
}
</script>

<style lang="scss" scoped>
</style>
<style lang="scss">
body {
  background-color: #fafafa !important;
}
</style>
