<template>
  <span>
    <el-popover placement="top" :width="`${width}px`" v-model="visible" :trigger="trigger" v-if="type == 'popover'" :open-delay="300">
      <div class="qr" style="padding:0 10px;">
        <img :src="src" alt />
        <div>
          13833393951
          <copy-icon :content="`13833393951`"></copy-icon>
        </div>
        <div style="margin-top: 10px;font-weight: bold;">{{ title }}</div>
        <div style="line-height: 20px; margin-top: 5px;" v-html="desc"></div>
      </div>
      <!-- slot="reference"  为 el-popover 的插槽 -->
      <slot slot="reference">
        <el-link :type="textType" :underline="false" icon="el-icon-headset">{{ text }}</el-link>
      </slot>
    </el-popover>
    <span v-if="type == 'dialog'">
      <span @click="handleOpen">
        <slot />
      </span>
      <el-dialog :title="title" append-to-body :visible.sync="visible" @close="close" width="300px">
        <div style="text-align:center;">
          <img :src="src" alt :width="width" :height="width" />
          <div style="line-height: 20px; margin-top: 10px;" v-html="desc"></div>
        </div>
      </el-dialog>
    </span>
  </span>
</template>
  
  <script>
export default {
  name: '',
  components: {},
  props: {
    width: {
      type: [String, Number],
      default: 200
    },
    textType: {
      type: String,
      default: 'primary'
    },
    text: {
      type: String,
      default: '咨询客服'
    },
    title: {
      type: String,
      default: '扫码添加客服'
    },
    desc: {
      type: String,
      default: '详细咨询'
    },
    src: {
      type: String,
      default: require('@/assets/images/service_qrcode_1.jpg')
    },
    trigger: {
      type: String,
      default: 'hover'
    },
    type: {
      type: String,
      default: 'popover' // dialog
    }
  },
  data() {
    return {
      visible: false
    }
  },
  watch: {},
  computed: {},
  created() {},
  methods: {
    handleOpen() {
      this.visible = true
    },
    close() {
      this.visible = false
    }
  },
  mounted() {}
}
</script>
  <style lang="scss" scoped>
.qr {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  span {
    font-size: 14px;
    margin-top: 5px;
    color: #666;
  }
  img {
    margin-top: 10px;
    width: 180px;
    height: 180px;
  }
}
</style>