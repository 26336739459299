import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
import Layout from '@/layout/index.vue'
// import HelpIndex from '@/views/help/index.vue'

Vue.use(Router)  //Vue全局使用Router

export default new Router({
  // mode: 'history', 
  routes: [
    {
      path: '/',
      component: Layout,
      name: 'home',
      children: [{
        path: '',
        component: () => import('@/views/home/index'),
        meta: { title: '' },
      }]
    },
    {
      path: '/download',
      component: Layout,
      name: 'Download',
      children: [{
        path: '',
        component: () => import('@/views/download/index'),
        meta: { title: '下载中心' },
      }]
    },
    {
      path: '/buy',
      component: Layout,
      name: 'Buy',
      children: [{
        path: '',
        component: () => import('@/views/buy/index'),
        meta: { title: '购买正版' },
      }]
    },
    {
      path: '/guestbook',
      component: Layout,
      name: 'Guestbook',
      children: [{
        path: '',
        component: () => import('@/views/guestbook/index'),
        meta: { title: '在线反馈' },
      }]
    },
    {
      path: '/agent',
      component: Layout,
      name: 'Agent',
      children: [{
        path: '',
        component: () => import('@/views/agent/index'),
        meta: { title: '代理加盟' },
      }]
    },
    {
      path: '/about',
      component: Layout,
      name: 'About',
      children: [{
        path: '',
        component: () => import('@/views/about/index'),
        meta: { title: '关于我们' },
      },
      ]
    },
    {
      path: '/post',
      component: Layout,
      name: 'Post',
      children: [{
        path: '',
        component: () => import('@/views/post/index'),
        meta: { title: '文章列表' },
        name: "PostIndex"
      },
      {
        path: 'detail/:code',
        component: () => import('@/views/post/detail'),
        meta: { title: '文章详情' },
        name: "PostDetail"
      }
      ]
    },
    {
      path: '/mtlk',
      component: Layout,
      name: 'Mtlk',
      children: [
        {
          path: ':code',
          component: () => import('@/views/mtlk/detail'),
          meta: { title: '介绍详情' },
          name: "MtlkDetail"
        }
      ]
    },
    {
      path: '/policy',
      name: 'Policy',
      component: () => import('@/views/policy/index'),
      meta: { title: '隐私政策' },
      hidden: true
    },

    {
      path: "/mobilePay",
      name: "MobilePay",
      component: () => import("@/views/mobilePay/index"),
      meta: { title: "在线支付" },
      hidden: true,
    },

    {
      path: "/tenantRegister",
      component: Layout,
      name: "TenantRegister",
      children: [{
        path: '',
        component: () => import("@/views/tenant/reg"),
        meta: { title: "网络版账号注册" },
      },
      ]
    },
    {
      path: "/tenantResetPassword",
      component: Layout,
      name: "TenantResetPassword",
      children: [{
        path: '',
        component: () => import("@/views/tenant/resetPassword"),
        meta: { title: "网络版重置密码" },
      },
      ]
    },
    // {
    //   path: '/mobilePay',
    //   component: Layout,
    //   name: 'MobilePay',
    //   children: [{
    //     path: '',
    //     component: () => import('@/views/mobilePay/index'),
    //     meta: { title: '在线支付' },
    //   },
    //   ]
    // },



    // 404 page must be placed at the end !!!
    { path: '*', redirect: '/', hidden: true }
  ]
})