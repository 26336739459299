
const gSetting = {}
// ================================================= 通用信息
gSetting.common = {
    name: '简单客 - 简单实用的管理软件',
    logo: require('@/assets/images/logo_header.png'),
    apiUrl: 'https://api.jiandanke.cn',
}

// ================================================= 开发环境API代理
if (process.env.NODE_ENV === 'development') {
    gSetting.common.apiUrl = '/agent_common_apiUrl'
}

export default gSetting
