<template>
  <div class="footer">
    <div class="footer-content">
      <div class="container">
        <div class="row">
          <div class="col-md col-6 f-box">
            <h3>维修系列</h3>
            <p @click="openUrl('http://sj.jiandanke.cn')">
              <a href>手机维修</a>
            </p>
            <p @click="openUrl('http://qc.jiandanke.cn')">
              <a href>汽车维修</a>
            </p>
            <p @click="openUrl('http://dn.jiandanke.cn')">
              <a href>家电维修</a>
            </p>
            <p @click="openUrl('http://es.jiandanke.cn')">
              <a href>二手机</a>
            </p>
          </div>
          <div class="col-md col-6 f-box">
            <h3>财务系列</h3>
            <p @click="openUrl('http://jz.jiandanke.cn')">
              <a href>往来记账</a>
            </p>
            <p>
              <a href="#" target="_blank">应收应付</a>
            </p>
            <p @click="openUrl('http://sz.jiandanke.cn')">
              <a href>出纳收支</a>
            </p>
            <p @click="openUrl('http://ht.jiandanke.cn')">
              <a href>合同账款</a>
            </p>
          </div>
          <div class="col-md col-6 f-box">
            <h3>收银系列</h3>
            <p>
              <a href="#">会员收银</a>
            </p>
            <p @click="openUrl('http://jx.jiandanke.cn')">
              <a href>进销存</a>
            </p>
          </div>
          <div class="col-md col-6 f-box">
            <h3>关于简单客</h3>
            <p>
              <router-link :to="{ path: 'about' }">公司介绍</router-link>
            </p>
            <p>
              <router-link :to="{ path: 'agent' }">申请代理</router-link>
            </p>
            <p>
              <a href="http://agent.jiandanke.cn/" target="_blank">代理商登录</a>
            </p>
            <p>
              <!-- <router-link :to="{ path: 'policy' }">隐私政策</router-link> -->
              <a href="./policy.html" target="_blank">隐私政策</a>
            </p>
            <p style="opacity: 0.2;">{{ invite||'~' }}</p>
          </div>
          <div class="col-md col-6 f-box">
            <h3>客户服务</h3>
            <p>
              <contact-service text="联系客服"></contact-service>
            </p>
            <p>商务：13833393951</p>
            <!-- <p>邮箱：bd@jiandanke.cn</p> -->
            <p>
              <router-link :to="{ path: 'tenantRegister' }">账号注册</router-link>
            </p>
            <p>
              <router-link :to="{ path: 'tenantResetPassword' }">重置密码</router-link>
            </p>
          </div>
          <div class="col-md col-6 f-box">
            <img src="@/assets/images/mp_qrcode_jiandanke.jpg" alt width="100" height="100" />
            <p>关注公众号</p>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom">Copyright © {{new Date().getFullYear()}} 畅麦科技 · 简单客</div>
  </div>
</template>

<script>
export default {
  name: 'footerBar',
  data() {
    return {
      invite: ''
    }
  },
  watch: {},
  computed: {},
  created() {
    this.invite = this.onloadArgs.getInvite()
  },
  methods: {
    openUrl(url) {
      let newUrl = url
      if (this.onloadArgs.getInvite()) {
        newUrl = newUrl + `/#/?invite=${this.onloadArgs.getInvite()}`
      }
      window.open(newUrl, '_blank ')
    }
  },

  mounted() {}
}
</script>
<style lang="scss" scoped>
@media screen and (max-width: 576px) {
  .footer {
    .f-box {
      margin-bottom: 0 !important;
    }
  }
}

.footer {
  width: 100%;
  color: #fff;
  .footer-content {
    background: #000000;
    .f-box {
      margin: 30px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      h3 {
        font-size: 20px;
        margin-bottom: 10px;
      }
      p {
        font-size: 14px;
        margin-bottom: 5px;
        opacity: 0.7;
      }
      a {
        color: #fff;
      }
    }
  }
  .footer-bottom {
    font-size: 14px;
    background: #2d3b4d;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>