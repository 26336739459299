import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/styles/common.scss' // 自定义全局css
import 'element-ui/lib/theme-chalk/index.css';

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = `${to.meta.title} - ${gSetting.common.name}`
  } else {
    document.title = gSetting.common.name
  }
  window.scrollTo(0, 0)
  next()
})


Vue.use(BootstrapVue)

Vue.config.productionTip = false

import gSetting from '@/common/setting/index'
Vue.prototype.gSetting = gSetting

import utils from '@/common/utils/utils'
Vue.prototype.utils = utils

import onloadArgs from '@/common/utils/onloadArgs.js'
Vue.prototype.onloadArgs = onloadArgs;

import 'element-ui/lib/theme-chalk/index.css'

import {
  MessageBox,
  Message,
  Dialog,
  Button,
  Form,
  Input,
  Select,
  Option,
  OptionGroup,
  FormItem, Row,
  Col,
  Pagination,
  Loading,
  Image,
  Upload,
  Popover,
  Link
} from 'element-ui';
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$message = Message;

Vue.use(Dialog)
Vue.use(Button)
Vue.use(Form)
Vue.use(Input)
Vue.use(Select)
Vue.use(Option)
Vue.use(OptionGroup)
Vue.use(FormItem)
Vue.use(Row)
Vue.use(Col)
Vue.use(Pagination)
Vue.use(Loading)
Vue.use(Image)
Vue.use(Upload)
Vue.use(Popover)
Vue.use(Link)


//添加客服
import ContactService from '@/components/ContactService'
Vue.component('ContactService', ContactService)


import CopyIcon from '@/components/CopyIcon'
Vue.component('CopyIcon', CopyIcon)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
