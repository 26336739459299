<template>
  <span @click.stop="handleCopy" style="color: #ccc;">
    <span v-if="content||!autoHide">
      <i v-if="showIcon" class="el-icon-document-copy copy-btn" :title="tips"></i>
    </span>
    <slot></slot>
  </span>
</template>

<script>
import clip from '@/utils/clipboard' // use clipboard directly

export default {
  components: {},
  props: {
    showIcon: {
      type: Boolean,
      default: true
    },
    content: {
      type: String,
      default: ''
    },
    autoHide: {
      type: Boolean,
      default: true
    },
    tips: {
      type: String,
      default: '复制'
    },
    successMessage: {
      type: String,
      default: '复制成功'
    }
  },

  data() {
    return {}
  },

  mounted() {},

  methods: {
    handleCopy(e) {
      if (!this.content) {
        this.$message({
          message: '无内容可复制',
          type: 'warning',
          duration: '1000'
        })
        return
      }
      clip(this.content, e)
      if (this.successMessage) {
        this.$message({
          message: this.successMessage,
          type: 'success',
          duration: '1000'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.copy-btn {
  cursor: pointer;
  color: #ccc;
  padding: 0 3px;
  &:hover {
    color: black;
  }
}
</style>