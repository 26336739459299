const state = {
    softCates: [],
    softList:[],
    guestbooks: [],
}
  
const mutations = {
    SET_SOFT_CATES: (state, data) => {
        state.softCates = data
    },
    SET_GUESTBOOKS: (state, data) => {
        state.guestbooks = data
    },
    SET_SOFT_LIST:(state, data)=>{
        state.softList = data
    }
}
  
const actions = {
    setSoftCates({ commit }, view) {
        commit('SET_SOFT_CATES', view)
    },
    setGuestbooks({ commit }, view) {
        commit('SET_GUESTBOOKS', view)
    },
    setSoftList({ commit }, view) {
        commit('SET_SOFT_LIST', view)
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
